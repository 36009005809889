@font-face {
  font-family: "MyFont";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

body {
  background-color: rgb(250, 250, 250);
  margin: 0;
}

h1,
h2,
p,
ul,
li {
  font-family: MyFont;
}

ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}

ul.header {
  background-color: rgb(8, 48, 75);
  padding: 0;
}

ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}

.content {
  background-color: #FFF;
  padding: 20px;
}

.content h2 {
  padding: 0;
  margin: 0;
}

.content li {
  margin-bottom: 10px;
}

.active {
  background-color: #0099FF;
}

.info-window {
  position: absolute;
  left: 0px;
  bottom: 100px;
  width: 90%;
  height: 150px;
  margin-left: 5%;
  margin-right: 5%;
  background: white;
  box-shadow: 5px 5px 5px rgba(128, 128, 128, 0.3);
  border-radius: 10px;
}

.searchbox-global {
  position: absolute;
  top: 0px;
  right: 13px;
  transition: width 0.5s;
  width: 45px
}
.searchbox-global.clicked {
  width: 240px; 
}

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}